import { useMemo } from 'react';
import { isReportContentItem } from '#/packages/content-item';
import { useContentItems } from '#/packages/content-item/hooks/useContentItems';
import { useSubscriberQuery } from '#/packages/subscription/queries/useSubscriberQuery';
import { useWorkspace } from '#/src/context/session/hooks/useWorkspace';
import { useUserQuery } from '#/src/queries/useUserQuery';
import { REPORTS_LIMIT } from '../constants/subscription-info';
import { Subscriber, SubscriberStatus, SubscriptionType } from '../types/Checkout';
import { getScaledValue } from '../utils/getScaledValue';

// TODO: It's a fallback to check if we don't lie to user about amount of actual content-items.
// Need to fix DB to handle it.
const calculateContentItemReportsLeft = (
  createdReports: number,
  contentItemReportsLeft?: number
) =>
  contentItemReportsLeft && contentItemReportsLeft > 0
    ? contentItemReportsLeft
    : REPORTS_LIMIT - createdReports;

export const useSubscription = () => {
  const [workspace] = useWorkspace();

  const { data: userInfo } = useUserQuery({ enabled: Boolean(workspace?.id) });
  const { data: subscriptionData, isLoading } = useSubscriberQuery(workspace?.id);

  const { sidebarContentItems } = useContentItems();

  const paid =
    subscriptionData?.type &&
    [SubscriptionType.PROFESSIONAL, SubscriptionType.AGENCY].includes(subscriptionData?.type) &&
    [SubscriberStatus.ACTIVE, SubscriberStatus.TRIALING].includes(subscriptionData.status);

  const createdReports = sidebarContentItems?.filter(isReportContentItem)?.length || 0;
  const contentItemReportsLeft = calculateContentItemReportsLeft(
    createdReports,
    subscriptionData?.flags.contentItemReportsLeft
  );

  // TODO: Move price fetching to the backend
  const scaledValue = getScaledValue(subscriptionData?.currentAdsSpent || 0);
  const subscriptionCostLimit = scaledValue.value;
  const subscriptionCost = scaledValue.price;

  const subscription = useMemo<
    | (Subscriber & {
        paid: boolean;
        subscriptionCost: string | number;
        subscriptionCostLimit: number | undefined;
        subscriptionOwnerIsMe: boolean | undefined;
      })
    | undefined
  >(() => {
    if (!subscriptionData) return undefined;

    return {
      ...subscriptionData,
      paid: Boolean(paid),
      subscriptionCost,
      subscriptionCostLimit,
      flags: {
        ...subscriptionData.flags,
        contentItemReportsLeft,
        contentItemsCreation: contentItemReportsLeft > 0
      },
      subscriptionOwnerIsMe: subscriptionData.subscriptionOwnerId === userInfo?.user.id
    };
  }, [
    subscriptionData,
    paid,
    subscriptionCost,
    subscriptionCostLimit,
    contentItemReportsLeft,
    userInfo?.user.id
  ]);

  return { subscription, isLoading };
};
