import { ContentItem, SidebarContentItem } from '../types/ContentItem';
import { isRootContentItem } from './';

export const getFindPath = (contentItems: ContentItem[]): ((id: string) => string[]) => {
  const cache = new Map<string, string[]>();
  const childrenAssigned = new Set();
  const parentMap = new Map<string, SidebarContentItem | null>(
    contentItems
      .map(
        (item) =>
          item.children
            ?.map((childId) => {
              if (
                !childrenAssigned.has(childId) &&
                childId !== item.id &&
                !contentItems?.find((i) => i.id === childId)?.deletedAt
              ) {
                childrenAssigned.add(childId);
                return [childId, item];
              } else {
                return null;
              }
            })
            .filter(Boolean) || []
      )
      .flat(1) as [string, SidebarContentItem | null][]
  );

  function findPath(currentId: string, currentPath: string[] = []): string[] {
    if (!cache.has(currentId)) {
      const parent = parentMap.get(currentId);

      if (parent?.id === currentId) {
        return [];
      }

      if (parent && !isRootContentItem(parent as ContentItem)) {
        cache.set(currentId, findPath(parent.id, [parent.id, ...currentPath]));
      } else {
        cache.set(currentId, currentPath.filter(Boolean));
      }
    }

    return cache.get(currentId) as string[];
  }

  return findPath;
};
