import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '#/src/api/backendHttpClient';

export type IUserInfo = {
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string;
    lastLogin: number;
    createdAt: number;
    updatedAt: number;
    deletedAt?: number;
  };
  isNewUser?: boolean;
};

export const getUseUserQueryKey = () => ['current-user'];

export const getUseUserQueryUrl = () => '/user/me';
export const getUseUpdateUserMutationUrl = () => '/user/profile';

export const useUserQuery = (opts?: { enabled?: boolean }) =>
  useQuery<IUserInfo>({
    queryKey: getUseUserQueryKey(),
    queryFn: () => httpClient.get<IUserInfo>(getUseUserQueryUrl()),
    ...opts
  });

export const useUpdateUserMutation = () =>
  useMutation({
    mutationKey: getUseUserQueryKey(),
    mutationFn: (userInfo: IUserInfo['user']) =>
      httpClient.put<IUserInfo>(getUseUpdateUserMutationUrl(), userInfo)
  });
