import { useMemo } from 'react';
import { Key } from 'react-aria-components';
import { useContentItemsQuery } from '#/packages/content-item/queries/useContentItemsQuery';
import { ContentItem } from '#/packages/content-item/types/ContentItem';
import {
  isFolderContentItem,
  isReportContentItem,
  isRootContentItem,
  isTempContentItem
} from '#/packages/content-item/utils/type';
import { REPORTS_LIMIT } from '#/packages/subscription/constants/subscription-info';
import { useSubscriberQuery } from '#/packages/subscription/queries/useSubscriberQuery';
import { SubscriberStatus } from '#/packages/subscription/types/Checkout';
import { useSessionWorkspaceId } from '#/src/context/session/hooks/useWorkspace';
import { sortItemsByRoot } from '../utils/sortItemsByRoot';

export const useContentItems = (props?: { enabled?: boolean }) => {
  const { enabled } = props || {};

  const workspaceId = useSessionWorkspaceId();
  const { data: subscriptionData, isLoading: isSubscriptionLoading } =
    useSubscriberQuery(workspaceId);

  const {
    data: contentItemsResult = [],
    isLoading: isContentItemsLoading,
    isSuccess: isContentItemsLoaded
  } = useContentItemsQuery(workspaceId || '', {
    enabled: enabled !== false && Boolean(workspaceId)
  });

  const loadedItems = useMemo<ContentItem[]>(() => {
    const itemsMap = new Map(contentItemsResult?.map((item) => [item.id, item]));
    const rootNode = contentItemsResult?.find(isRootContentItem);

    if (!rootNode) {
      return [];
    }

    const usedIds = new Set();
    const result: ContentItem[] = [];

    function processNode(node?: ContentItem) {
      if (!node || usedIds.has(node?.id)) {
        return;
      }

      usedIds.add(node.id);
      const newNode = { ...node };
      result.push(newNode);

      if (
        !node.children ||
        node.children.length === 0 ||
        (!isFolderContentItem(node) && !isRootContentItem(node))
      ) {
        newNode.children = [];
        return;
      }

      const validChildren = [];

      for (const childId of node.children) {
        if (!itemsMap.has(childId) || usedIds.has(childId)) {
          continue;
        }

        const childNode = itemsMap.get(childId);
        validChildren.push(childId);
        processNode(childNode);
      }
      newNode.children = validChildren;
    }

    processNode(rootNode);

    return result;
  }, [contentItemsResult]);

  const itemsParentMap = useMemo(
    () =>
      new Map<string, ContentItem['id'] | null>(
        loadedItems.map((item) => item.children.map((childId) => [childId, item.id])).flat(1) as [
          string,
          ContentItem['id'] | null
        ][]
      ),
    [loadedItems]
  );

  const contentItems = useMemo(
    () =>
      loadedItems
        ?.filter(
          (item) =>
            isTempContentItem(item) || isRootContentItem(item) || itemsParentMap.has(item.id)
        )
        .map((item) => ({
          ...item,
          children:
            item.children?.filter(
              (childId) => childId !== item.id && loadedItems.some((i) => i.id === childId)
            ) || []
        }))
        .sort((a, b) => {
          if (isReportContentItem(a) && !isReportContentItem(b)) return -1;
          if (!isReportContentItem(a) && isReportContentItem(b)) return 1;
          return a.createdAt - b.createdAt;
        })
        .map((item, index) => ({
          ...item,
          blocked:
            !isSubscriptionLoading &&
            subscriptionData?.status &&
            ![SubscriberStatus.ACTIVE, SubscriberStatus.TRIALING].includes(
              subscriptionData?.status
            ) &&
            isReportContentItem(item) &&
            index >= REPORTS_LIMIT
        })),
    [loadedItems, itemsParentMap, isSubscriptionLoading, subscriptionData?.status]
  );

  const contentItemsMap = useMemo(
    () => new Map<Key, ContentItem>(contentItems?.map((it) => [it.id, it])),
    [contentItems]
  );

  const contentItemsRootId = useMemo(
    () => contentItems.find((it) => isRootContentItem(it))?.id,
    [contentItems]
  );

  const contentItemsRoot = useMemo(
    () => (contentItemsRootId ? contentItemsMap.get(contentItemsRootId) : null),
    [contentItemsRootId, contentItemsMap]
  );

  const sidebarContentItems = useMemo(
    () => sortItemsByRoot(contentItemsRoot?.children, contentItemsMap),
    [contentItemsMap, contentItemsRoot?.children]
  );

  return {
    contentItems,
    contentItemsMap,
    sidebarContentItems,
    contentItemsRootId,
    contentItemsRoot,
    isContentItemsLoading,
    isContentItemsLoaded,
    itemsParentMap
  };
};
