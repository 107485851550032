import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { Workspace } from '#/packages/workspace/types/Workspace';
import backendHttpClient from '#/src/api/backendHttpClient';
import { Subscriber } from '../types/Checkout';

export type SubscriberQueryKey = [string, Workspace['id'] | null | undefined];

export const getSubscriberQueryKey = (workspaceId?: Workspace['id'] | null): SubscriberQueryKey => [
  'subscriber',
  workspaceId
];

export const getSubscriberQueryUrl = (workspaceId?: Workspace['id'] | null) =>
  workspaceId ? `/subscriber/${workspaceId}` : '';

export const useSubscriberQuery = (workspaceId?: Workspace['id'] | null, opts?: any) => {
  return useQuery<Subscriber | null, AxiosError, Subscriber | null, SubscriberQueryKey>({
    queryKey: getSubscriberQueryKey(workspaceId),
    queryFn: ({ queryKey }) =>
      queryKey[1] && backendHttpClient.get(getSubscriberQueryUrl(queryKey[1])),
    enabled: Boolean(workspaceId),
    ...opts
  });
};
