import { PROFESSIONAL_PRICE_SCALE } from '../constants/subscription-info';

// Accepts ad spends, returns percentage and price
export const getScaledValue = (linearValue: number) => {
  const index = PROFESSIONAL_PRICE_SCALE.findIndex(({ value }) => linearValue < value);
  const currentScale = PROFESSIONAL_PRICE_SCALE[index];

  if (index === -1) {
    return { price: currentScale?.price, percentage: currentScale?.percentage };
  }

  const { value: startValue, percentage: startPercentage } = PROFESSIONAL_PRICE_SCALE[index - 1];
  const { value: endValue, percentage: endPercentage } = currentScale;

  const percentageInRange =
    endPercentage === 100
      ? 90
      : ((linearValue - startValue) / (endValue - startValue)) * (endPercentage - startPercentage) +
        startPercentage;

  return { percentage: percentageInRange, price: currentScale.price, value: currentScale.value };
};

// Accepts percentage, returns ad spend and price
export const getValueFromPercentage = (percentage: number) => {
  const index = PROFESSIONAL_PRICE_SCALE.findIndex(
    ({ percentage: scalePercentage }) => percentage < scalePercentage
  );

  if (index === -1) {
    return {
      adSpend: PROFESSIONAL_PRICE_SCALE[PROFESSIONAL_PRICE_SCALE.length - 1].value,
      price: PROFESSIONAL_PRICE_SCALE[PROFESSIONAL_PRICE_SCALE.length - 1].price
    };
  }

  const { value: startValue, percentage: startPercentage } = PROFESSIONAL_PRICE_SCALE[index - 1];
  const { value: endValue, percentage: endPercentage, price } = PROFESSIONAL_PRICE_SCALE[index];

  const adSpend =
    ((percentage - startPercentage) / (endPercentage - startPercentage)) * (endValue - startValue) +
    startValue;

  return { adSpend, price };
};
