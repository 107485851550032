import { PAYWALL_TYPE, SubscriberStatus, SubscriptionType } from '../types/Checkout';

export const SUBSCRIPTION_BADGES: Record<
  SubscriberStatus,
  { color: 'callout' | 'primary' | 'neutral'; title: string }
> = {
  [SubscriberStatus.FREE]: { color: 'neutral', title: 'Active plan' },
  [SubscriberStatus.ACTIVE]: { color: 'primary', title: 'Active plan' },
  [SubscriberStatus.TRIALING]: { color: 'neutral', title: 'Free trial' },
  [SubscriberStatus.CANCELLED]: { color: 'callout', title: 'Cancelled' },
  [SubscriberStatus.UNKNOWN]: { color: 'callout', title: 'Something went wrong' }
};

export const SUBSCRIPTION_TYPE_TITLE = {
  [SubscriptionType.COMMUNITY]: 'Free',
  [SubscriptionType.PROFESSIONAL]: 'Professional',
  [SubscriptionType.AGENCY]: 'Agency Partner'
};

export const PROFESSIONAL_PRICE_SCALE: {
  percentage: number;
  value: number;
  price: number | string;
}[] = [
  { percentage: 0, value: 0, price: 49 },
  { percentage: 4, value: 25000, price: 49 },
  { percentage: 8, value: 50000, price: 99 },
  { percentage: 21, value: 250000, price: 249 },
  { percentage: 32.5, value: 500000, price: 499 },
  { percentage: 44, value: 1000000, price: 799 },
  { percentage: 49.25, value: 1250000, price: 999 },
  { percentage: 54.5, value: 1500000, price: 1199 },
  { percentage: 59.75, value: 1750000, price: 1399 },
  { percentage: 65, value: 2000000, price: 1599 },
  { percentage: 70, value: 2250000, price: 1799 },
  { percentage: 75, value: 2500000, price: 1999 },
  { percentage: 80, value: 2750000, price: 2199 },
  { percentage: 85, value: 3000000, price: 2399 },
  { percentage: 90, value: 3250000, price: 2599 },
  { percentage: 95, value: 3500000, price: 2799 },
  { percentage: 100, value: 3750000, price: 2999 }
];

export const REPORTS_LIMIT = 10;

export const PAYWALL_DATA = {
  [PAYWALL_TYPE.TWO_MONTHS_LIMIT]: {
    subtitle:
      'Upgrade to view data beyond 60 days and unlock all Superads premium features to enhance your creative and marketing performance.'
  }
} as Record<PAYWALL_TYPE, { subtitle: string }>;
